import styled from "@emotion/styled";
import { Alert, Button, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, Grid, Link, MenuItem, Skeleton, Stack, Switch, TextField, Typography, capitalize, useTheme } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link as RRLink } from "react-router-dom";
import { createReservation, getReservationTimes } from "../service/store.service";
import { FRONT_URLS } from "../config/url.config";

const DatePicker = styled(DateCalendar)(({ theme }) => ({
    /*'.MuiDateCalendar-root': {
        width: '100%',
        height: 'auto',
    },*/
    '.MuiIconButton-root': {
        color: 'inherit',
    },
    '.MuiDayCalendar-weekDayLabel': {
        //backgroundColor: theme.palette.primary.main,
        color: 'inherit',
        fontSize: '0.8em',
        //width: '55px',
        margin: '0',
        padding: '0 2px'
    },
    /*'.MuiDayCalendar-weekDayLabel:first-child': {
        borderRadius: '10px 0 0 10px'
    },
    '.MuiDayCalendar-weekDayLabel:last-child': {
        borderRadius: '0 10px 10px 0'
    },*/
    '.MuiPickersDay-root': {
        fontSize: '0.8em',
        //width: '55px',
        //height: '55px',
        //margin: '0',
    },
    '.MuiDayCalendar-slideTransition': {
        //minHeight: 'auto'
        backgroundColor: 'white'
    },
    /*'.MuiMonthCalendar-root': {
        width: 'auto'
    },
    '.MuiDayCalendar-monthContainer': {
        position: 'relative'
    }*/
}));

export default function Reservation() {
    const theme = useTheme();
    const variant = "outlined";

    const [date, setDate] = useState(null);
    const [time, setTime] = useState('');
    const [people, setPeople] = useState(2);
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [policy, setPolicy] = useState(false);

    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [timeList, setTimeList] = useState();
    const [error, setError] = useState(false);
    const [response, setResponse] = useState();

    useEffect(() => {
        if (date !== null) {
            setResponse(null);
            setLoading(true);
            setTime('');
            getReservationTimes(dayjs(date).format('YYYYMMDD'))
                .then(tl => setTimeList(tl))
                .catch(e => setTimeList([]))
                .finally(() => setLoading(false));
        }
    }, [date]);

    const validateMail = () => {
        const validMail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (mail && !validMail.test(mail)) {
            return false;
        }
        return true;
    }

    const validate = () => {
        if (
            date === null ||
            time === '' ||
            people === null ||
            name === null ||
            (mail === null && phone === null) ||
            !policy
        ) {
            setError(true);
            return false;
        }
        if (mail && !validateMail()) {
            setError(true);
            return false;
        }
        return true;
    }

    const sendReservation = () => {
        if (!validate()) return;
        setSending(true);
        setResponse(null);
        createReservation({
            date: dayjs(date).format('DD/MM/YYYY'),
            time,
            people,
            name,
            mail,
            phone,
            comment
        })
            .then(() => setResponse("success"))
            .catch(() => setResponse("error"))
            .finally(() => setSending(false));
    }

    return (
        <Container sx={{ marginBottom: 3 }}>
            <Typography>Seleccione la fecha y hora de reserva</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <DatePicker
                        value={date}
                        onChange={setDate}
                        views={['month', /*'year',*/ 'day']}
                        minDate={dayjs()}
                        dayOfWeekFormatter={(day, date) => {
                            return capitalize(dayjs(date).format('ddd').substring(0, 3)) // Para quitar el punto al final
                        }}
                        style={{
                            boxShadow: '2px 1px 4px 1px #c1c1c1',
                            marginBlock: 20,
                            borderRadius: '5px',
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {date && (timeList?.length === 0 ? (
                        <Alert severity="error">No hay horas disponibles para el día seleccionado</Alert>
                    ) : (
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={1}>
                                {loading ? (
                                    <Skeleton variant="rounded" width="100%" height="100%">
                                        <TextField fullWidth />
                                    </Skeleton>
                                ) : (
                                    timeList && <TextField select
                                        variant={variant}
                                        fullWidth
                                        label="Hora"
                                        value={time}
                                        onChange={e => setTime(e.target.value)}
                                        error={error && !time}
                                        helperText={error && !time ? 'Debe seleccionar una hora' : ''}
                                    >
                                        {timeList?.map(time => (
                                            <MenuItem key={time} value={time}>{time}</MenuItem>
                                        ))}
                                    </TextField>
                                )}

                                <TextField
                                    select
                                    variant={variant}
                                    fullWidth
                                    label="Personas"
                                    value={people}
                                    onChange={e => setPeople(e.target.value)}
                                    error={error && !people}
                                    helperText={error && !people ? 'Debe seleccionar el número de personas' : ''}
                                >
                                    {Array(10).fill(1).map((x, i) => (
                                        <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
                                    ))}
                                </TextField>
                            </Stack>

                            <TextField
                                label="Nombre"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                variant={variant}
                                error={error && !name}
                                helperText={error && !name ? 'Indique un nombre para la reserva' : ''}
                            />

                            <TextField
                                label="Correo electrónico"
                                value={mail}
                                onChange={e => setMail(e.target.value)}
                                variant={variant}
                                error={error && ((!mail && !phone) || !validateMail())}
                                helperText={error && !mail && !phone ? 'Es necesario informar el correo o el teléfono' : (error && !validateMail() ? 'La dirección de correo no es válida' : '')}
                            />

                            <TextField
                                label="Teléfono"
                                type="number"
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                variant={variant}
                                error={error && !mail && !phone}
                                helperText={error && !mail && !phone ? 'Es necesario informar el correo o el teléfono' : ''}
                            />

                            <TextField label="Comentario" multiline rows={3} value={comment} onChange={e => setComment(e.target.value)} variant={variant} />

                            <FormControl error={error && !policy}>
                                <FormControlLabel
                                    control={<Switch name="policy" color="secondary" checked={policy} onChange={() => setPolicy(!policy)} />}
                                    label={<div>He leído la <Link component={RRLink} target="_blank" to={`/${FRONT_URLS.privacy}`}>Política de privacidad</Link> y doy mi consentimiento para el tratamiento de datos</div>}
                                />
                                {error && !policy && (<FormHelperText>Tiene que marcar el consentimiento para el tratamiento de datos</FormHelperText>)}
                            </FormControl>

                            {response && (
                                <Alert severity={response}>
                                    {response === 'success' ?
                                        'Su reserva se ha tramitado correctamente, en breve recibirá información a través del correo o teléfono.' :
                                        'Ha ocurrido un error al tramitar su reserva. Inténtelo de nuevo o pruebe por via telefónica.'}
                                </Alert>
                            )}

                            {response !== 'success' && (
                                <Button variant="contained" onClick={sendReservation} disabled={sending}>
                                    Enviar
                                    {sending && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Button>
                            )}
                        </Stack>
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
}